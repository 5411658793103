import { RequestError } from "./RequestError";
import { BadRequestError } from "./BadRequestError";
import { UnAuthenticatedError } from "./UnAuthenticatedError";
import { UnAuthorizedError } from "./UnAuthorizedError";
import { NotFoundError } from "./NotFoundError";
import { UnprocessableEntityError } from "./UnprocessableEntityError";
import { UnverifiedError } from "./UnverifiedError";
import { ServerError } from "./ServerError";
import { TooManyRequestsError } from "./TooManyRequestsError";

export class ErrorRegistration {
  statusCodeClasses = {
    301: new RequestError(),
    400: new BadRequestError(),
    401: new UnAuthenticatedError(),
    403: new UnAuthorizedError(),
    404: new NotFoundError(),
    422: new UnprocessableEntityError(),
    429: new TooManyRequestsError(),
    436: new UnverifiedError(),
    500: new ServerError(),
  };

  getClassFromStatusCode(code) {
    return this.statusCodeClasses[code];
  }
}
