<template>
    <v-btn
      v-if="paginatedCollection.currentPage>1 && !isLoading"
      color="secondary"
      :disabled="paginatedCollection.currentPage==1"
      text
      @click="onclick()"
      v-bind="$attrs"
    >
      <v-icon>mdi-arrow-right</v-icon>
      السابق
    </v-btn>
  </template>
  
  <script>
  import { mapState } from "pinia";
  import { useGlobalStore } from "@/store/GlobalStore";
  import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
  
  export default {
    props: {
      paginatedCollection: BasePaginatedCollection,
      onclick: Function,
    },
    computed: {
      ...mapState(useGlobalStore, ["isLoading"]),
    },
  };
  </script>
  
  <style></style>
  