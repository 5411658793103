<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> فرص العمل </template>
    <template v-slot:tableActions>
      <CreateJobButton />
      <JobFilter class="mr-5" />
    </template>
    <template v-slot:dataTable>
      <JobOpportunitiesTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import JobOpportunitiesTable from "../components/JobOpportunitiesTable.vue";
import JobFilter from "../components/JobFilter.vue";
import CreateJobButton from "../components/action_buttons/CreateJobButton.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة فرص العمل",
          disabled: true,
        },
      ],
    };
  },
  components: { JobOpportunitiesTable, JobFilter, CreateJobButton },
};
</script>
