<template>
  <v-container fluid>
    <!--circular background-->
    <div class="circle circle-1 d-none d-md-flex"></div>
    <div class="circle circle-2"></div>
    <div class="circle circle-3"></div>

    <!-- Contents -->
    <v-container class="d-flex fill-hight-container" fluid>
      <RegisterIntroLogo />
      <RegisterIntroText />
      <RegisterIntroIllustration />
    </v-container>
  </v-container>
</template>

<script>
import RegisterIntroLogo from "./RegisterIntroLogo.vue";
import RegisterIntroText from "./RegisterIntroText.vue";
import RegisterIntroIllustration from "./RegisterIntroIllustration.vue";

export default {
  components: {
    RegisterIntroLogo,
    RegisterIntroText,
    RegisterIntroIllustration,
  },
};
</script>

<style scoped>
.fill-hight-container {
  position: relative;
  min-height: 100vh;
}

.circle {
  position: fixed;
  right: -100px;
  top: -200px;
  border-radius: 50%;
  background-color: #a87c4f;
  z-index: 0;
  opacity: 0.2;
}

.circle-1 {
  width: 1000px;
  height: 1000px;
  animation: circle-animation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.circle-2 {
  width: 750px;
  height: 750px;
  animation: circle-animation;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
}

.circle-3 {
  width: 500px;
  height: 500px;
  animation: circle-animation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
}

@keyframes circle-animation {
  0% {
    top: -1346px;
    right: -1075px;
  }

  100% {
    right: -100px;
    top: -200px;
  }
}
</style>
