import JobAcceptedNotification from "./notification_types/JobAcceptedNotification";
import JobRejectedNotification from "./notification_types/JobRejectedNotification";
import NewApplicantNotification from "./notification_types/NewApplicantNotification";
import Notification from "./Notification";

export default class NotificationFactory {

  static wrapNotification(data) {
    // based on notification type make specified notification instances
    if (data.type == "App\\Notifications\\JobAccepted")
      return new JobAcceptedNotification(data);
    else if (data.type == "App\\Notifications\\JobRejected")
      return new JobRejectedNotification(data);
    else if (data.type == "App\\Notifications\\NewJobApplicant")
      return new NewApplicantNotification(data);
    else return new Notification(data);
  }

}
