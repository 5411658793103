import { defineStore } from "pinia";
import { useAuthStore } from "@/modules/auth/store/AuthStore";

import Profile from "../models/Profile";
import BaseStore from "@/core/classes/base/BaseStore";
import ProfileService from "../services/ProfileService";

const authStore = useAuthStore();

export const useProfileStore = defineStore("ProfileStore", {
  state: () => ({
    profile: {},
    initialProfile:{},
    isEditable: false,
  }),
  getters: {
    omitProfileLogoIfString(){
      var profile=new Object(this.profile);
      if(typeof(this.profile.logo)=='string'){
        profile.logo=undefined;
      }
      return profile;
    }
  },
  actions: {
    resetProfileToInitialState(){
      this.profile=this.initialProfile;
    },
    changePassword(data) {
      return BaseStore.promiseHandler(() =>
        ProfileService.changePassword(data)
      );
    },
    getProfile() {
      return BaseStore.promiseHandler(
        () => ProfileService.getProfile(),
        (data) => {
          this.profile = new Profile(data.data);
          this.initialProfile= new Profile(data.data);
        }
      );
    },
    editProfile() {
      return BaseStore.promiseHandler(
        () => ProfileService.editProfile(this.omitProfileLogoIfString),
        (data) => {
          this.profile = new Profile(data.data);
          this.updateAuthStoreState();
          this.updateLocalStorageData();
        }
      );
    },
    updateLocalStorageData() {
      localStorage.setItem("name", this.profile.name);
      localStorage.setItem("logo", this.profile.logo);
    },
    updateAuthStoreState() {
      authStore.name = this.profile.name;
      authStore.logo = this.profile.logo;
    },
  },
});
