import {
  jobTranslations,
  requiredExperienceLevelCodes,
} from "./static_enum_translations/JobEnums";
import keysTransformations from "./JobKeysTransformations";
import Field from "./Field";

export default class JobOpportunity {
  constructor(data) {
    this.id = data?.id;
    this.title = data?.title;
    this.createdAt = data?.created_at;
    this.status = data?.settings?.status;
    this.applicantsCount = data?.applications_count;
    this.fields = [];
    this.fieldsIds=[];
    data?.fields?.map((field)=>{
      this.fields.push(new Field(field));
      this.fieldsIds.push(field.id);
    });
    this.requiredExperience = data?.required_experience_level;
    this.requiredExperienceLevel =
      requiredExperienceLevelCodes[this.requiredExperience]; //code mapping
    this.isSalaryShown = data?data.settings?.is_salary_showed:false;
    this.minSalary = data?.salary_min;
    this.maxSalary = data?.salary_max;
    this.workLocationType = data?.work_location_type;
    this.workLocation = data?.work_location;
    this.shiftType = data?.shift_type;
    this.workHours = data?.details?.work_hours;
    this.minAge = data?.details?.age_min;
    this.maxAge = data?.details?.age_max;
    this.ageIsUndetermined=(!this.minAge && !this.maxAge);
    this.requiredEducation = data?.details?.certificate;
    this.requiredEducationLevel = data?.details?.certificate_required_level;
    this.requiredMilitaryServiceStatus =
      data?.details?.military_service_termination;
    this.requiredGender = data?.details?.gender;
    this.requiredSkills = data?.details?.skills_required;
    this.extraRequirements = data?.details?.extra_requirements;
    this.responsibilities = data?.details?.responsibilities;
    this.publishedAt = data?.accepted_at;
  }
  pluckFieldNames(){
    var names=[];
    this.fields.map((field)=>{
      names.push(field.name);
    })
    return names;
  }
  static transformKeysForSend(job) {
    var transformedJob={};
    for(let key in job){
      transformedJob[keysTransformations[key]]=job[key];
    }
    return transformedJob;
  }

  static trans(prop) {
    return jobTranslations[prop];
  }
}
