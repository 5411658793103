import BaseService from "@/core/classes/base/BaseService";
import {
  changePasswordAPI,
  editProfileAPI,
  getProfileAPI,
} from "./Profile.api";
import Client from "@/core/classes/client/Client";
import Mapper from "@/core/classes/mapper/Mapper";

export default class ProfileService extends BaseService {
  static async changePassword(data) {
    const res = await Client.post(
      changePasswordAPI,
      Mapper.camelToUnderscore(data)
    );
    return await Promise.resolve(res);
  }
  static async getProfile() {
    const res = await Client.get(getProfileAPI);
    return await Promise.resolve(res);
  }
  static async editProfile(data) {
    const res = await Client.put(
      editProfileAPI,
      Mapper.camelToUnderscore(data),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return await Promise.resolve(res);
  }
}
