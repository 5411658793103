<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> تعديل فرصة عمل</template>
    <v-container>
      <v-row>
        <v-col v-if="!fetchingData" cols="12">
          <EditJobForm :job="job" />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import EditJobForm from "../components/forms/EditJobForm.vue";
import { mapActions, mapState } from "pinia";
import { useJobOpportunityStore } from "../store/JobOpportunityStore";

export default {
  mounted() {
    var jobId = this.$route.params.id;
    this.fetchJob(jobId)
    .then(()=>{
        this.fetchingData=false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useJobOpportunityStore, ["job"]),
  },
  methods: {
    ...mapActions(useJobOpportunityStore, ["fetchJob"]),
  },
  data() {
    return {
      fetchingData:true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة فرص العمل",
          href: "/job-opportunities",
          disabled: false,
        },
        {
          text: "تعديل الفرصة رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { EditJobForm },
};
</script>
