<template>
  <v-form ref="form">
    <!-- Email -->
    <BaseTextField
      v-model="email"
      :rules="[requiredRule, emailRule]"
      persistent-placeholder
      outlined
      type="email"
      class="mb-2"
      label="البريد الالكتروني"
      placeholder="البريد الالكتروني"
      prepend-inner-icon="mdi-email"
      @keyup.enter="validateThePostLogin()"
    >
    </BaseTextField>

    <!-- Password -->
    <BasePasswordField
      v-model="password"
      :rules="[requiredRule, passwordRule]"
      outlined
      persistent-placeholder
      type="password"
      label="كلمة السر"
      placeholder="كلمة السر"
      prepend-inner-icon="mdi-key"
      @keyup.enter="validateThePostLogin()"
    >
    </BasePasswordField>
    <v-row class="mb-2">
      <v-col cols="12" justify="start" align="start">
        <v-btn text color="primary" to="/reset-password">هل نسيت كلمة السر؟</v-btn>
      </v-col>
    </v-row>

    <v-btn class="accent rounded-xl px-8" @click="validateThePostLogin()"
      >تسجيل الدخول</v-btn
    >
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

import { mapActions } from "pinia";
import { useAuthStore } from "../../store/AuthStore";

export default {
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["postLogin"]),
    validateThePostLogin() {
      if (this.$refs.form.validate()) {
        var loginData = { email: this.email, password: this.password };
        this.postLogin(loginData)
          .then((res) => {
            if (res.data.data.email_verified_at == null) {
              this.$router.push({
                name: "verify_email",
                params: { resendCodeOnMounted: true },
              });
            } else {
              this.$router.push("/");
            }
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 500px;
}
</style>
