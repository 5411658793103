import UserLayout from "@/core/layouts/UserLayout.vue";
import VerifyEmailPage from "../pages/VerifyEmailPage.vue";

export default {
  path: "/verify-email",
  component: UserLayout,
  children: [
    {
      path: "/verify-email",
      name: "verify_email",
      component: VerifyEmailPage,
      meta: { title: "تأكيد الحساب" },
      props: true,
    },
  ],
};
