<template>
  <v-card class="light py-10 rounded-xl">
    <h1 class="primary--text text-center">تسجيل الدخول</h1>
    <v-container class="mt-5">
      <v-row>
        <v-col align="center">
          <LoginCardForm />

          <div class="mt-5">
            <v-btn text @click="redirectToRegisterPage()">
              ليس لديك حساب؟
              <span class="accent--text text-decoration-underline">
                أنشئ حساباً
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import LoginCardForm from "./LoginCardForm.vue";
export default {
  components: { LoginCardForm },
  methods: {
    redirectToRegisterPage() {
      this.$router.push("register");
    },
  },
};
</script>

<style></style>
