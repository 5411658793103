<template>
  <v-container class="mt-5">
    <v-row>
      <v-col align="center">
        <v-form ref="form">
          <p>أدخل رمز التأكيد</p>
          <v-otp-input
            v-model="otp"
            :rules="[requiredRule, codeRule]"
            class="otp"
            length="6"
          ></v-otp-input> 

          <p class="mt-5">أدخل كلمة السر الجديدة</p>
          <BasePasswordField
            v-model="password"
            :rules="[requiredRule, passwordRule]"
            outlined
            persistent-placeholder
            type="password"
            label="كلمة السر الجديدة"
            placeholder="كلمة السر الجديدة"
            prepend-inner-icon="mdi-key"
          >
          </BasePasswordField>
        </v-form>
        <v-btn
          class="accent rounded-xl mt-5 px-8"
          @click="validateThenEmitCompleted()"
          >إعادة تعيين كلمة السر</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

export default {
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      otp: "",
      password: "",
    };
  },
  methods: {
    validateThenEmitCompleted() {
      if (this.$refs.form.validate()) {
        this.$emit("completed", { token: this.otp, password: this.password });
      }
    },
  },
};
</script>

<style scoped>
.otp {
  direction: ltr;
}
.v-otp-input {
  max-width: 400px;
}
.v-text-field {
  max-width: 400px;
}
</style>
