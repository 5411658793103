import BaseService from "@/core/classes/base/BaseService";
import {
  getJobsAPI,
  getJobAPI,
  sendJobRenewalRequestAPI,
  sendJobCloseRequestAPI,
  sendCreatedJobAPI,
  sendEditedJobAPI,
  getJobFieldsAPI
} from "./JobOpportunities.api";
import Mapper from "@/core/classes/mapper/Mapper";
import JobOpportunity from "../models/JobOpportunity";
import Client from "@/core/classes/client/Client";

export default class JobOpportunitiesService extends BaseService {
  static getPaginatedJobOpportunities(params) {
    return Client.get(getJobsAPI, params).then((res) => Promise.resolve(res));
  }
  static getJobOpportunity(id) {
    return Client.get(getJobAPI(id)).then((res) => Promise.resolve(res));
  }
  static getJobFields() {
    return Client.get(getJobFieldsAPI).then((res) => Promise.resolve(res));
  }
  static sendJobRenewalRequest(id, data) {
    return Client.post(
      sendJobRenewalRequestAPI(id),
      Mapper.camelToUnderscore(data)
    ).then((res) => Promise.resolve(res));
  }
  static sendJobCloseRequest(id, data) {
    return Client.put(
      sendJobCloseRequestAPI(id),
      Mapper.camelToUnderscore(data)
    ).then((res) => Promise.resolve(res));
  }
  static sendCreateJobRequest(data) {
    return Client.post(
      sendCreatedJobAPI,
      JobOpportunity.transformKeysForSend(data)
    ).then((res) => Promise.resolve(res));
  }
  static sendEditJobRequest(id,data) {
    return Client.put(
      sendEditedJobAPI(id),
      JobOpportunity.transformKeysForSend(data)
    ).then((res) => Promise.resolve(res));
  }
}
