import UserLayout from "@/core/layouts/UserLayout.vue";
import JobApplicantsPage from "../pages/JobApplicantsPage.vue";

export default {
  path: "/job-opportunities/:id/applicants",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "job_applicants",
      component: JobApplicantsPage,
      meta: { title: "المتقدمين" },
    },
  ],
};
