<template>
  <v-container class="lighterSecondary" fill-height fluid>
    <RegisterIntro />
    <RegisterForm />
  </v-container>
</template>

<script>
import RegisterIntro from "../components/register/RegisterIntro.vue";
import RegisterForm from "../components/register/RegisterForm.vue";

export default {
  components: {
    RegisterIntro,
    RegisterForm,
  },
};
</script>

<style scoped></style>
