<template>
  <v-img
    class="offering-job"
    src="@/assets/illustrations/offering-job.png"
    contain
  ></v-img>
</template>

<script>
export default {};
</script>

<style scoped>
.offering-job {
  position: absolute;
  bottom: 0;
  left: 0;
  max-height: 60%;
  opacity: 0.3;
}
</style>
