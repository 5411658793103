import JobOpportunity from "../models/JobOpportunity";
import { defineStore } from "pinia";
import JobOpportunitiesService from "../services/JobOpportunitiesService";
import {useJobOpportunitiesStore} from "./JobOpportunitiesStore"
import Field from "../models/Field";

export const useJobOpportunityStore = defineStore("jobOpportunityStore", {
  state: () => ({
    job: new JobOpportunity(),
    jobFields:[],
  }),
  getters: {
    allJobOpportunities(){
       const jobOpportunitiesStore=useJobOpportunitiesStore();
       return jobOpportunitiesStore.paginatedJobs.collection;
    }
  },
  actions: {
    fetchJobFields(){
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.getJobFields()
          .then(res => {
            let { data, status } = res;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.jobFields=[];
              data.data.map((field)=>{
                this.jobFields.push(new Field(field))
              });
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    fetchJob(id) {
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.getJobOpportunity(id)
          .then(res => {
            let { data, status } = res;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.job = new JobOpportunity(data.data);
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    sendRenewalRequest(id,reasonData) {
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.sendJobRenewalRequest(id,reasonData)
          .then(res => {
            let { data, status } = res;
            data;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.allJobOpportunities.map((job)=>{
                if(job.id==id){
                  job.status="pending"
                }
              })
              this.job.status="pending";
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    sendCloseRequest(id,closeData) {
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.sendJobCloseRequest(id,closeData)
          .then(res => {
            let { data, status } = res;
            data;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              this.allJobOpportunities.map((job)=>{
                if(job.id==id){
                  job.status="closed"
                }
              })
              this.job.status="closed";
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    sendJobCreationRequest(jobData){
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.sendCreateJobRequest(jobData)
          .then(res => {
            let { data, status } = res;
            data;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    sendJobEditRequest(jobId,jobData){
      return new Promise((resolve, reject) => {
        JobOpportunitiesService.sendEditJobRequest(jobId,jobData)
          .then(res => {
            let { data, status } = res;
            data;
            return JobOpportunitiesService.resolveStatusResponse(
              status,
              res
            ).then(() => {
              return resolve(res);
            });
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  }
});
