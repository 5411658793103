export default {
  id: "id",
  status: "status",
  rate: "rate",
  rateContent: "rate_content",
  isOrganizationGraduate: "is_organization_graduate",
  createdAt: "created_at",
  resumeId: "resume_id",
  jobResume: "job_resume",
  "applicant.name": "users.name",
  "applicant.email": "users.email",
  "applicant.phoneNumber": "users.phone_number",
};
