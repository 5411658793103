<template>
  <BaseCRUDPageLayout :links="links">
    <v-container>
      <v-row justify="start">
        <v-col class="my-10" cols="12" sm="6">
          <v-form ref="form">
            <BasePasswordField
              class="my-2"
              v-model="oldPassword"
              :rules="[requiredRule, passwordRule]"
              type="password"
              label="كلمة السر القديمة"
              placeholder="ضع كلمة سر حسابك القديمة"
            />
            <BasePasswordField
              class="my-2"
              v-model="newPassword"
              :rules="[requiredRule, passwordRule]"
              type="password"
              label="كلمة السر الجديدة"
              placeholder="أنشأ كلمة سر جديدة لحسابك"
            />
            <BasePasswordField
              class="my-2"
              :rules="[
                requiredRule,
                passwordRule,
                confirmPasswordRule(newPassword),
              ]"
              type="password"
              label="تأكيد كلمة السر الجديدة"
              placeholder="قم بتأكيد كلمة السر الجديدة"
            />
          </v-form>
          <v-btn class="accent px-9" large @click="validateThenChangePassword()"
            >تغيير كلمة السر</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { EventBus } from "@/main";
import { mapActions } from "pinia";
import { useProfileStore } from "../store/ProfileStore";

export default {
  mixins: [FormValidationRulesMixin],
  watch:{
    oldPassword: {
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "الملف الشخصي",
          href: "/profile",
          disabled: false,
        },
        {
          text: "تعديل بيانات الحساب",
          href: "/edit-account-information",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions(useProfileStore, ["changePassword"]),
    validateThenChangePassword() {
      if (this.$refs.form.validate()) {
        EventBus.$emit('formSubmitted');
        var data = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
        this.changePassword(data)
          .then(() => {
            this.$router.push("/");
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style></style>
