<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="11">
          <ProfileBreadcrumbs />
          <v-container v-if="!fetchingData" class="my-5">
            <v-form ref="form">
              <ProfileHeading :formIsValid="formIsValid" />
              <ProfileBody />
            </v-form>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ProfileBreadcrumbs from "../components/ProfileBreadcrumbs.vue";
import ProfileHeading from "../components/ProfileHeading.vue";
import ProfileBody from "../components/ProfileBody.vue";

import { useProfileStore } from "../store/ProfileStore.js";
import { useGlobalStore } from "@/store/GlobalStore";
import { mapActions, mapState } from "pinia";

const ProfileStore = useProfileStore();

export default {
  components: { ProfileBreadcrumbs, ProfileHeading, ProfileBody },
  data() {
    return {
      fetchingData: true,
    };
  },
  computed: {
    ...mapState(useGlobalStore, ["isLoading"]),
  },
  methods: {
    ...mapActions(useProfileStore, ["getProfile"]),
    formIsValid() {
      return this.$refs.form.validate();
    },
  },
  mounted() {
    this.getProfile()
      .then(() => (this.fetchingData = false))
      .catch((err) => console.error(err));
  },
  destroyed() {
    ProfileStore.$reset();
  },
};
</script>

<style></style>
