import UserLayout from "@/core/layouts/UserLayout.vue";
import JobOpportunitiesPage from "../pages/JobOpportunitiesPage.vue";
import JobOpportunityPage from "../pages/JobOpportunityPage.vue";
import CreateJobPage from "../pages/CreateJobPage.vue";
import EditJobPage from "../pages/EditJobPage.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";

export default {
  path: "/job-opportunities",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "job_opportunities",
      component: JobOpportunitiesPage,
      meta: { title: "فرص العمل" },
    },
    {
      path: "/job-opportunities/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "create_job_opportunity",
          component: CreateJobPage,
          meta: { title: "إنشاء فرصة عمل" },
        },
      ],
    },
    {
      path: "/job-opportunities/:id",
      name: "job_opportunity",
      component: JobOpportunityPage,
      meta: { title: "فرصة عمل" },
    },
    {
      path: "/job-opportunities/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "edit_job_opportunity",
          component: EditJobPage,
          meta: { title: "تعديل فرصة عمل" },
        },
      ],
    },
  ],
};
