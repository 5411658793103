<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">إغلاق الفرصة</p>
    </template>
    <template v-slot:body>
      <p>
        يجب أن يمر طلب إغلاق الفرصة على الإدارة لتقوم بمراجعة السبب، الرجاء
        إرسال سبب الإغلاق
      </p>
      <small class="secondary--text">
        إذا لم تجد السبب المناسب ضمن الخيارات قم بكتابة السبب ضمن الحقل
      </small>
      <v-form ref="form">
        <v-combobox
          class="mt-5"
          v-model="selectedClosingReason"
          clearable
          chips
          :items="[...closingReasons, selectedClosingReason]"
          label="سبب الإغلاق"
          placeholder="سبب الإغلاق"
          persistent-placeholder
          outlined
          :rules="[requiredRule]"
        >
        </v-combobox>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn color="accent" rounded @click="sendJobClosingData()"
        >إغلاق الطلب</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { mapActions } from "pinia";
import { useJobOpportunityStore } from "../../store/JobOpportunityStore";

export default {
  props: {
    showCondition: Boolean,
    item: Object,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      selectedClosingReason: null,
      show: this.showCondition,
      closingReasons: [
        "إلغاء الوظيفة",
        "تم تأمين الشاغر من المتقدمين",
        "تم تأمين الشاغر من خارج المنصة",
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$refs.form.reset();
      this.show = false;
      this.$emit("closed");
    },
    ...mapActions(useJobOpportunityStore, ["sendCloseRequest"]),
    sendJobClosingData() {
      if (this.$refs.form.validate()) {
        var closeData = { reason: this.selectedClosingReason };
        this.sendCloseRequest(this.item.id, closeData)
          .then(() => {})
          .catch(() => {});
        this.closeDialog();
      }
    },
  },
};
</script>
