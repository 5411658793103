import UserLayout from "@/core/layouts/UserLayout.vue";
import RegisterPage from "../pages/RegisterPage.vue";

export default {
  path: "/register",
  component: UserLayout,
  children: [
    {
      path: "/register",
      name: "register",
      component: RegisterPage,
      meta: { title: "إنشاء حساب" },
    },
  ],
};
