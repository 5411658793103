import { Notify } from "../notify/Notify";
import { BaseError } from "../base/BaseError";
import router from "@/router";

export class UnverifiedError extends BaseError {
  message = "يرجى تأكيد البريد الإلكتروني الخاص بك";

  dispatchError() {
    this.redirectToVerifyEmailPage();
    Notify.showMessage("warning", this.message);
  }

  redirectToVerifyEmailPage() {
    router.push({
      name: "verify_email",
      params: { resendCodeOnMounted: true },
    });
  }
}
