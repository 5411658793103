<template>
  <div>
    <div v-if="!isEditable">
      <v-btn color="accent rounded-xl pa-6" @click="toggleEditingStatus()">
        تعديل بيانات الشركة
        <v-icon class="mr-4">mdi-pencil</v-icon>
      </v-btn>
      <br />
      <v-btn
        color="accent rounded-xl pa-6 mt-4"
        @click="redirectToEditAccountInfoPage()"
      >
        تعديل بيانات الحساب
        <v-icon class="mr-4">mdi-cog</v-icon>
      </v-btn>
    </div>
    <div v-else>
      <v-row align="center" justify="center">
        <v-btn
          class="accent rounded-xl pa-6 my-3 mx-3" 
          @click="validateThenEditProfile()"
        >
          حفظ التغييرات
          <v-icon class="mr-4">mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn
          class="rounded-xl outlined secondary--text pa-6 my-3 mx-3"
          @click="cancelEditing()"
        >
          إلغاء التعديل
          <v-icon class="mr-4">mdi-arrow-left</v-icon>
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import { useProfileStore } from "../store/ProfileStore";
import { EventBus } from "@/main";

export default {
  props: {
    formIsValid: Function,
  },
  computed: {
    ...mapWritableState(useProfileStore, ["isEditable"]),
  },
  methods: {
    ...mapActions(useProfileStore, ["editProfile","resetProfileToInitialState"]),
    validateThenEditProfile() {
      if (this.formIsValid()) {
        EventBus.$emit("formSubmitted");
        this.editProfile()
          .then(() => {
            this.isEditable = false;
          })
          .catch(() => {});
      }
    },
    cancelEditing(){
      EventBus.$emit("formSubmitted");
      this.resetProfileToInitialState();
      this.toggleEditingStatus();
    },
    toggleEditingStatus() {
      this.isEditable = !this.isEditable;
    },
    redirectToEditAccountInfoPage() {
      this.$router.push("/edit-account-information");
    },
  },
};
</script>

<style></style>
