<template>
  <v-text-field
    v-on="$listeners"
    v-bind="$attrs"
    outlined
    persistent-placeholder
  ></v-text-field>
</template>

<script>
export default {
  name: "BaseTextField",
};
</script>
