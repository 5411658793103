<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <v-text-field
            label="المسمى الوظيفي"
            persistent-placeholder
            outlined
            placeholder="مثال: محاسب"
            v-model="job.title"
            :rules="[requiredRule]"
          ></v-text-field>

          <v-autocomplete
            v-model="job.fieldsIds"
            chips
            clearable
            item-value="id"
            :items="jobFields"
            :filter="customFilter"
            label="مجال فرصة العمل"
            hint="يمكنك اختيار عدة مجالات"
            persistent-hint
            outlined
            multiple
            :rules="[requiredRule]"
          >
            <template v-slot:item="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>{{ item.name }}</v-chip>
            </template>
          </v-autocomplete>

          <v-select
            v-model="job.shiftType"
            chips
            clearable
            item-value="value"
            :items="shiftTypes"
            label="نوع الدوام"
            placeholder="نوع الدوام"
            persistent-placeholder
            outlined
            :rules="[requiredRule]"
          >
            <template v-slot:item="{ item }">
              {{ item.label }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>{{ item.label }}</v-chip>
            </template>
          </v-select>

          <v-text-field
            label="عدد ساعات العمل"
            placeholder="عدد ساعات العمل"
            persistent-placeholder
            outlined
            hint="في حال كان عدد الساعات غير محدد قم بوضع(غير محدد)"
            v-model="job.workHours"
            :rules="[requiredRule]"
          ></v-text-field>

          <v-combobox
            v-model="job.requiredSkills"
            :items="job.requiredSkills"
            label="المهارات المطلوبة"
            placeholder=" أضف المهارات المطلوبة (مثال: استخدام برنامج الأمين)"
            persistent-placeholder
            outlined
            hint="ضع عنوان المهارة ثم انقر enter"
            prepend-inner-icon="mdi-plus"
            chips
            multiple
            :rules="[requiredRule, notEmptyRule]"
          ></v-combobox>

          <v-select
            v-model="job.requiredExperience"
            chips
            clearable
            item-value="value"
            :items="experienceLevels"
            label="مستوى الخبرة "
            placeholder="مستوى الخبرة "
            persistent-placeholder
            outlined
            :rules="[requiredRule]"
          >
            <template v-slot:item="{ item }">
              {{ item.label }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>{{ item.label }}</v-chip>
            </template>
          </v-select>

          <v-select
            v-model="job.workLocationType"
            chips
            clearable
            item-value="value"
            :items="jobLocationTypes"
            label="نوع موقع العمل"
            placeholder="نوع موقع العمل"
            persistent-placeholder
            outlined
            :rules="[requiredRule]"
          >
            <template v-slot:item="{ item }">
              {{ item.label }}
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>{{ item.label }}</v-chip>
            </template>
          </v-select>

          <v-text-field
            v-model="job.workLocation"
            v-if="
              job.workLocationType == 'on_site' ||
              job.workLocationType == 'hybrid'
            "
            label="موقع العمل"
            placeholder="موقع العمل"
            persistent-placeholder
            outlined
            :rules="[requiredRule]"
          ></v-text-field>

          <v-row align="center">
            <div class="ml-5">مجال الراتب</div>
            <v-text-field
              class="mx-1"
              label="بداية مجال الراتب"
              placeholder="بداية مجال الراتب (مقدر ب ل.س)"
              v-model="job.minSalary"
              :rules="[requiredRule, numberRule]"
              :hint="job.minSalary ? formatCurrency(job.minSalary) : ''"
              persistent-hint
              persistent-placeholder
              outlined
            ></v-text-field>

            <v-text-field
              class="mx-1"
              label="نهاية مجال الراتب"
              placeholder="نهاية مجال الراتب (مقدر ب ل.س)"
              v-model="job.maxSalary"
              :rules="[requiredRule, numberRule]"
              :hint="job.maxSalary ? formatCurrency(job.maxSalary) : ''"
              persistent-hint
              persistent-placeholder
              outlined
            ></v-text-field>

            <v-checkbox
              class="mx-1"
              label="إظهار الراتب للمتقدمين"
              v-model="job.isSalaryShown"
            ></v-checkbox>
          </v-row>

          <v-row align="center">
            <div class="ml-5">مجال العمر</div>
            <v-text-field
              class="mx-1"
              v-show="!job.ageIsUndetermined"
              label="بداية مجال العمر"
              placeholder="بداية مجال العمر"
              v-model="job.minAge"
              :rules="[numberOrNullRule]"
              persistent-placeholder
              outlined
            ></v-text-field>

            <v-text-field
              class="mx-1"
              v-show="!job.ageIsUndetermined"
              label="نهاية مجال العمر"
              placeholder="نهاية مجال العمر"
              v-model="job.maxAge"
              :rules="[numberOrNullRule]"
              persistent-placeholder
              outlined
            ></v-text-field>

            <v-checkbox
              class="mx-1"
              v-model="job.ageIsUndetermined"
              label="غير محدد"
            ></v-checkbox>
          </v-row>

          <v-row align="center">
            <div class="ml-5">التحصيل العلمي</div>
            <v-text-field
              v-if="job.requiredEducationLevel != 'not_required'"
              :rules="[requiredRule]"
              label="الشهادة العلمية المطلوبة"
              placeholder="مثال:معهد تجاري"
              persistent-placeholder
              outlined
              v-model="job.requiredEducation"
            ></v-text-field>

            <v-radio-group
              :rules="[requiredRule]"
              row
              v-model="job.requiredEducationLevel"
            >
              <v-radio label="يشترط" value="required"></v-radio>
              <v-radio label="يفضل" value="preferred"></v-radio>
              <v-radio label="غير مطلوب" value="not_required"></v-radio>
            </v-radio-group>
          </v-row>

          <v-row align="center">
            <div class="ml-5">إنهاء خدمة العلم</div>
            <v-radio-group
              :rules="[requiredRule]"
              row
              v-model="job.requiredMilitaryServiceStatus"
            >
              <v-radio label="يشترط" value="required"></v-radio>
              <v-radio label="يفضل" value="preferred"></v-radio>
              <v-radio label="غير مطلوب" value="not_required"></v-radio>
            </v-radio-group>
          </v-row>

          <v-row align="center">
            <div class="ml-5">الجنس المطلوب</div>
            <v-radio-group
              :rules="[requiredRule]"
              row
              v-model="job.requiredGender"
            >
              <v-radio label="ذكر" value="m"></v-radio>
              <v-radio label="أنثى" value="f"></v-radio>
              <v-radio label="غير محدد" value="non"></v-radio>
            </v-radio-group>
          </v-row>

          <v-row>
            <v-textarea
              label="المهام والمسؤوليات"
              placeholder=" صف المهام والمسؤوليات"
              persistent-placeholder
              outlined
              v-model="job.responsibilities"
              :rules="[requiredRule]"
            ></v-textarea>
          </v-row>

          <v-row>
            <v-textarea
              label="متطلبات إضافية"
              placeholder="اذكر أية متطلبات إضافية"
              persistent-placeholder
              outlined
              v-model="job.extraRequirements"
            ></v-textarea>
          </v-row>

          <v-row align="center" justify="center">
            <v-btn
              class="my-5"
              color="accent"
              x-large
              rounded
              @click="emitSubmission()"
            >
              {{ formActionName }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import {
  shiftTypes,
  experienceLevels,
  jobLocationTypes,
} from "../statics/JobOptions";
import { EventBus } from "@/main";
import CurrencyFormattingMixin from "@/core/mixins/CurrencyFormattingMixin.vue";
import { mapActions, mapState } from "pinia";
import { useJobOpportunityStore } from "../../store/JobOpportunityStore";

export default {
  props: {
    jobObject: Object,
    formActionName: String,
  },
  mounted() {
    this.fetchJobFields()
      .then()
      .catch((err) => console.error(err));
  },
  data() {
    return {
      job: this.jobObject,
      shiftTypes: shiftTypes,
      experienceLevels: experienceLevels,
      jobLocationTypes: jobLocationTypes,
    };
  },
  computed: {
    ...mapState(useJobOpportunityStore, ["jobFields"]),
  },
  watch: {
    jobObject: {
      immediate: true,
      handler() {
        this.job = this.jobObject;
      },
    },
    "job.ageIsUndetermined": function (newVal) {
      if (newVal) {
        this.job.minAge = null;
        this.job.maxAge = null;
      }
    },
    job: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mixins: [FormValidationRulesMixin, CurrencyFormattingMixin],
  methods: {
    customFilter(field, queryText) {
      const fieldText = field.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        fieldText.indexOf(searchText) > -1 
      );
    },
    emitSubmission() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("formSubmitted");
        this.$emit("jobSubmitted", this.job);
      }
    },
    ...mapActions(useJobOpportunityStore, ["fetchJobFields"]),
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
</style>
