<template>
  <div>
    <TopNav v-if="isAuthenticated" />
    <SideNav v-if="isAuthenticated" />
    <router-view :class="{ 'page-view': isAuthenticated }"></router-view>
    <LoadingProgress />
    <NotificationAlert />
  </div>
</template>
<script>
import TopNav from "../components/navs/TopNav.vue";
import SideNav from "../components/navs/SideNav";
import LoadingProgress from "../components/loader/LoadingProgress";
import NotificationAlert from "../components/notifications/NotificationAlert";

export default {
  components: {
    NotificationAlert,
    LoadingProgress,
    SideNav,
    TopNav,
  },
  computed: {
    isAuthenticated() {
      return !["login", "register", "verify_email","reset_password"].includes(this.$route.name);
    },
  },
};
</script>

<style scoped>
.page-view {
  margin-top: 35px;
  min-height: 80vh;
}
</style>
