import Notification from "../Notification";

export default class JobAcceptedNotification extends Notification{
    constructor(data){
        super(data);
        this.jobId=data.data.job_id;
        this.jobTitle=data.data.job_title;
    }
    getTitle(){
        return 'تم قبول نشر فرصة عمل'
    }
    getBody(){
        return `لقد تم قبول نشر فرصة العمل الخاصة بك "${this.jobTitle}" ذات الرقم : #${this.jobId}`
    }
    getActionLink(){
        return `/job-opportunities/${this.jobId}`
    }
}