<template>
  <v-container id="register-form" class="pa-0" fluid>
    <v-row class="light" align="center" justify="center">
      <v-col
        class="my-14"
        align="center"
        justify="center"
        cols="12"
        sm="10"
        md="5"
      >
        <h2 class="primary--text mb-10">في حال رغبتكم بالإعلان عن الشواغر لخرجي البركة فيرجى منكم ملء المعلومات الواردة أدناه</h2>

        <v-form ref="form">
          <!-- Name -->
          <BaseTextField
            :rules="[requiredRule]"
            v-model="registerForm.name"
            class="mb-2"
            label="اسم الشركة"
            placeholder="اسم الشركة"
            prepend-inner-icon="mdi-account"
            @keyup.enter="validateThenOpenTermsNConditionsDialog()"
          />

          <!-- Field -->
          <BaseTextField
            :rules="[requiredRule]"
            v-model="registerForm.field"
            class="mb-2"
            label="مجال العمل"
            placeholder="مجال العمل"
            prepend-inner-icon="mdi-domain"
            @keyup.enter="validateThenOpenTermsNConditionsDialog()"
          />

          <!-- Location -->
          <BaseTextField
            :rules="[requiredRule]"
            v-model="registerForm.location"
            class="mb-2"
            label="موقع الشركة"
            placeholder="موقع الشركة"
            prepend-inner-icon="mdi-map-marker"
            @keyup.enter="validateThenOpenTermsNConditionsDialog()"
          />

          <!-- Logo -->
          <v-file-input
            v-model="registerForm.logo"
            outlined
            persistent-placeholder
            class="mb-2"
            label="شعار الشركة"
            accept="image/*"
            placeholder="قم بتحميل صورة شعار الشركة"
            prepend-icon=""
            prepend-inner-icon="mdi-image"
          ></v-file-input>

          <!-- Phone Number -->
          <BaseTextField
            :rules="[requiredRule]"
            v-model="registerForm.phoneNumber"
            persistent-placeholder
            type="number"
            class="mb-2"
            placeholder="رقم الهاتف"
            label="رقم الهاتف"
            prepend-inner-icon="mdi-phone"
            @keyup.enter="validateThenOpenTermsNConditionsDialog()"
          />

          <!-- Email -->
          <BaseTextField
            :rules="[requiredRule, emailRule]"
            v-model="registerForm.email"
            type="email"
            class="mb-2"
            label="البريد الالكتروني"
            placeholder="البريد الالكتروني"
            prepend-inner-icon="mdi-email"
            @keyup.enter="validateThenOpenTermsNConditionsDialog()"
          />

          <!-- About -->
          <BaseTextArea
            :rules="[requiredRule]"
            v-model="registerForm.about"
            persistent-placeholder
            class="mb-2"
            label="عن الشركة"
            placeholder="صف الشركة، الخدمات أو المنتجات التي تقدمها، وماهي الميزات التي تتمتع بها"
            prepend-inner-icon="mdi-information"
            @keyup.enter="validateThenOpenTermsNConditionsDialog()"
          />

          <!-- Password -->
          <BasePasswordField
            v-model="registerForm.password"
            :rules="[requiredRule, passwordRule]"
            type="password"
            class="mb-2"
            label="كلمة السر"
            placeholder="كلمة السر"
            prepend-inner-icon="mdi-key"
            @keyup.enter="validateThenOpenTermsNConditionsDialog()"
          />

          <!-- Confirm Password -->
          <BasePasswordField
            v-model="registerForm.passwordConfirmation"
            :rules="[requiredRule, confirmPasswordRule(registerForm.password)]"
            type="password"
            class="mb-2"
            label="تأكيد كلمة السر"
            placeholder="تأكيد كلمة السر"
            prepend-inner-icon="mdi-key"
            @keyup.enter="validateThenOpenTermsNConditionsDialog()"
          />
        </v-form>

        <v-btn
          class="accent mt-5 px-5 py-5 rounded-xl"
          @click="validateThenOpenTermsNConditionsDialog()"
        >
          التسجيل ضمن المنصة
        </v-btn>

        <div class="mt-5">
          <v-btn text @click="redirectToLoginPage()">
            لديك حساب بالفعل؟
            <span class="accent--text text-decoration-underline">
              سجل الدخول
            </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <TermsNConditionsDialog
      :showCondition="showDialog"
      @closed="closeTermsNConditionsDialog"
    />
  </v-container>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import TermsNConditionsDialog from "./TermsNConditionsDialog.vue";

import { mapWritableState } from "pinia";
import { useAuthStore } from "../../store/AuthStore.js";

export default {
  components: { TermsNConditionsDialog },
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapWritableState(useAuthStore, ["registerForm"]),
  },
  methods: {
    validateThenOpenTermsNConditionsDialog() {
      if (this.$refs.form.validate()) {
        this.openTermsNConditionsDialog();
      }
    },
    openTermsNConditionsDialog() {
      this.showDialog = true;
    },
    closeTermsNConditionsDialog() {
      this.showDialog = false;
    },
    redirectToLoginPage() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped></style>
