<template>
  <div style="position: relative">
    <!-- Picture Avatar -->
    <v-avatar class="lightNeutral elevation-10" size="200">
      <v-img
        v-if="profile.logo != null"
        contain
        :src="
          typeof profile.logo == 'string' ? profile.logo : getPictureFileUrl()
        "
        class="rounded"
      ></v-img>
      <v-icon v-else x-large color="lightSecondary">mdi-domain</v-icon>
    </v-avatar>

    <!-- Edit icon -->
    <input
      type="file"
      ref="file"
      accept="image/*"
      @change="uploadPicture"
      hidden
    />
    <v-btn
      v-if="isEditable"
      class="lightSecondary edit_icon"
      fab
      @click="choosePicture()"
    >
      <v-icon color="secondary"> mdi-pencil </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useProfileStore } from "../store/ProfileStore";

export default {
  computed: {
    ...mapState(useProfileStore, ["isEditable"]),
    ...mapWritableState(useProfileStore, ["profile"]),
  },
  methods: {
    choosePicture() {
      this.$refs.file.click();
    },
    uploadPicture(event) {
      this.profile.logoChanged = 1;
      this.profile.logo = event.target.files[0];
    },
    getPictureFileUrl() {
      return URL.createObjectURL(this.profile.logo);
    },
  },
};
</script>

<style>
.edit_icon {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
