<template>
  <v-card class="light py-10 rounded-xl">
    <h1 class="primary--text text-center">تأكيد الحساب</h1>
    <p class="text-center mx-4">
      لقد قمنا بإرسال رمز التأكيد إلى بريدك الالكتروني، تفقد بريدك وأدخل الرمز
    </p>
    <v-container class="mt-5">
      <v-row>
        <v-col align="center">
          <v-form ref="form">
            <v-otp-input
              v-model="otp"
              :rules="[requiredRule, codeRule]"
              class="otp"
              length="6"
            ></v-otp-input>
          </v-form>
          <v-btn
            class="accent rounded-xl mt-5 px-8"
            @click="validateTheVerifyEmail()"
            >تأكيد الحساب</v-btn
          >
          <div class="mt-5">
            <v-btn text @click="resendVerificationCode()">
              لم يصلك رمز التأكيد؟
              <span class="accent--text text-decoration-underline">
                أعد إرسال الرمز
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

import { mapActions } from "pinia";
import { useAuthStore } from "../../store/AuthStore";

export default {
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      otp: "",
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["verifyEmail", "resendVerificationCode"]),
    validateTheVerifyEmail() {
      if (this.$refs.form.validate()) {
        var verifyEmailData = { token: this.otp };
        this.verifyEmail(verifyEmailData)
          .then(() => {
            this.$router.push("/");
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
.otp {
  max-width: 400px;
  direction: ltr;
}
</style>
