<template>
  <v-row align="center" justify="center">
    <v-col align="center" justify="center" cols="12" sm="10" md="6">
      <p class="secondary--text" style="font-size: 35px; font-weight: bold">
        هل ترغب بنشر فرصة عمل خاصة بك؟
      </p>
      <p class="primary--text" style="font-size: 30px">
        قم بالتسجيل ضمن المنصة كناشر لفرص العمل، وابدأ بنشر فرص عملك وإدارتها
      </p>
      <v-btn fab @click="scrollToRegisterForm()" class="accent mt-10">
        <v-icon color="white" size="55">mdi-menu-down</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  methods: {
    scrollToRegisterForm() {
      window.scrollTo({
        top: document.getElementById("register-form").offsetTop,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style></style>
