import { defineStore } from "pinia";

import AuthService from "../services/AuthService";
import BaseStore from "@/core/classes/base/BaseStore";

export const useAuthStore = defineStore("AuthStore", {
  state: () => ({
    name: localStorage.getItem("name"),
    logo: localStorage.getItem("logo"),
    registerForm: {},
  }),
  actions: {
    postRegister() {
      return BaseStore.promiseHandler(
        () => AuthService.register(this.registerForm),
        (data) => {
          this.storeUserData(data.data);
        }
      );
    },
    postLogin(data) {
      return BaseStore.promiseHandler(
        () => AuthService.login(data),
        (data) => {
          this.storeUserData(data.data);
          this.setStateData(data.data);
        }
      );
    },
    verifyEmail(data) {
      return BaseStore.promiseHandler(() => AuthService.verifyEmail(data));
    },
    sendVerificationCodeForReset(data) {
      return BaseStore.promiseHandler(() =>
        AuthService.sendVerificationCodeForReset(data)
      );
    },
    resetPassword(data) {
      return BaseStore.promiseHandler(() =>
        AuthService.resetPassword(data)
      );
    },
    resendVerificationCode() {
      return BaseStore.promiseHandler(() =>
        AuthService.resendVerificationCode()
      );
    },
    logout() {
      return BaseStore.promiseHandler(
        () => AuthService.logout(),
        () => {
          localStorage.clear();
          this.name = null;
          this.logo = null;
        }
      );
    },
    setStateData(data) {
      this.name = data.name;
      this.logo = data.logo
        ? process.env.VUE_APP_BASE_URL + data.logo
        : undefined;
    },
    storeUserData(data) {
      localStorage.setItem("name", data.name);
      localStorage.setItem("token", data.token);
      localStorage.setItem(
        "logo",
        data.logo ? process.env.VUE_APP_BASE_URL + data.logo : undefined
      );
    },
  },
});
