<template>
  <v-col align="center" class="mt-5" cols="12" sm="6">
    <p class="head primary--text">{{ title }}</p>
    <v-card
      class="lightNeutral rounded-xl"
      height="200px"
      max-width="350px"
      elevation="10"
    >
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col align="center" justify="center" style="position: relative">
            <p class="value accent--text">{{ value }}</p>
            <p class="unit primary--text">{{ unit }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    unit: String,
    title: String,
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.head {
  font-size: 28px;
}
.value {
  font-size: 50px;
}
.unit {
  margin-top: -30px;
  left: 0;
  right: 0;
  min-width: 20px;
  padding-right: 90px;
  position: absolute;
}
</style>
