export const requiredExperienceLevelCodes = {
  0: "متدرب(خبرة أقل من سنة)",
  1: "مبتدئ(خبرة سنة أو سنتين)",
  2: "متوسط(خبرة 3 أو 4 سنوات)",
  3: "متقدم (خبرة أكثر من 5 سنوات)"
};
export const jobTranslations = {
  full_time: "دوام كامل",
  part_time: "دوام جزئي",
  tasks: "مهام",
  night_shift: "دوام ليلي",
  remotely: "عمل عن بعد",
  on_site: "عمل ضمن موقع عمل ",
  hybrid: "مزيج بين عمل عن بعد وعمل ضمن موقع عمل",
  required: "يُشترط",
  not_required: "غير مطلوب",
  preferred: "يفضّل",
  m: "ذكر",
  f: "أنثى",
  non: "غير محدد",
  pending:"قيد المعالجة",
  open:"مفتوحة",
  closed:"مغلقة",
  rejected:"مرفوضة",
};
