<template>
  <BaseTableItemAction
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-eye"
    actionName="عرض التفاصيل"
    :to="'/job-opportunities/' + item.id"
  >
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {},
};
</script>
