<template>
  <v-data-table
    :headers="headers"
    :items="paginatedItems.collection"
    :items-per-page="paginatedItems.perPage"
    :options.sync="options"
    :server-items-length="paginatedItems.total"
    class="elevation-5 mt-5"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <template v-slot:top>
      <v-progress-linear
        v-if="isLoading"
        :indeterminate="true"
        color="accent"
      ></v-progress-linear>
      <v-text-field
        v-if="searchable"
        clearable
        @input="emitDebouncedSearch()"
        v-model="search"
        placeholder="ابحث"
        class="px-2"
      >
        <template v-slot:append>
          <v-icon large color="lightSecondary">mdi-magnify</v-icon>
        </template>
      </v-text-field>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <slot name="actions" :item="item"></slot>
    </template>
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
      ><slot :name="name" v-bind="slotData"></slot
    ></template>
  </v-data-table>
</template>

<script>
import { useGlobalStore } from "@/store/GlobalStore";
import { mapState } from "pinia";

export default {
  name: "BaseDataTable",
  props: {
    headers: Array,
    paginatedItems: Object,
    searchable:{
      type:Boolean,
      default:true
    }
  },
  computed: {
    ...mapState(useGlobalStore, ["isLoading"]),
  },
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      search: null,
      searchTimeout: null,
    };
  },
  methods: {
    emitDebouncedSearch() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$emit("debouncedSearch", this.search);
      }, 500);
    },
  },
  watch: {
    options() {
      this.$emit("optionsChanged", this.options);
    },
  },
};
</script>
