<template>
  <BaseTableItemAction
    v-if="item.status == 'closed'"
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-clock-plus-outline"
    actionName="تمديد الفرصة"
    @click="openRenewalDialog()"
  >
    <RenewJobDialog
      :showCondition="showRenewalDialog"
      :item="item"
      @closed="closeRenewalDialog"
    />
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import RenewJobDialog from "../dialogs/RenewJobDialog.vue";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      showRenewalDialog: false,
    };
  },
  methods: {
    openRenewalDialog() {
      this.showRenewalDialog = true;
    },
    closeRenewalDialog() {
      this.showRenewalDialog = false;
    },
  },
  components: { RenewJobDialog },
};
</script>
